.product-service-section {
  width: 100%;
  padding: 10px 0;
  .service-section-inner {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 96%;
    margin: auto;
    text-align: left;
    padding: 10px 3px;

    .service-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: 10px 5px;
      flex-direction: column; // Change to column on mobile

      &.desktop-layout {
        flex-direction: row; // Keep row direction on desktop
      }

      .service-icon {
        margin-right: 10px;
        margin-bottom: 5px; // Add margin for spacing on mobile

        img {
          // width: 20px;
          // height: auto;
        }
      }

      .service-info {
        text-align: center; // Center-align text on mobile
        p {
          margin: 0;
          font-size: 10px;
          color: #666;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .service-section {
    .service-section-inner {
      width: 100%;
      margin: auto;
    }
  }
}
