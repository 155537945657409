.product-info-container {
  padding: 16px;
  border-radius: 8px;
  background-color: white;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .product-info-row {
    display: flex;
    align-items: center;

    padding: 10px;
  }

  .product-info-block {
    margin-bottom: 16px; /* Adds spacing between blocks */
  }

  .product-info-title {
    font-size: 16px;
    color: #888888;
    display: inline-block;
    margin-right: 8px;
  }

  .product-info-value {
    font-size: 16px;
    color: #010035;
    font-weight: bold;
    display: inline-block;
  }

  .divider-col {
    display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .divider {
    height: 100px;
    width: 1px;
    background-color: #eaeaea;
  }
}
